import React from "react";

const TermsAndCondition = () => {
 
  return (
    <div className="terms-and-conditions-content">
      <h2 className="terms-and-conditions-title-intro">
        {"Terms & Conditions"}
      </h2>
      <div className="terms-and-conditions-body">
        {`By clicking `}
        <strong className="terms-and-condition-highlight">"Accept & Continue"</strong>
        {` or by accessing and using this internet service, you accept and agree to be bound by the 
        terms and conditions of this agreement as set out below. If you do not agree, please do not used this service.`}
      </div>
      <h2 className="terms-and-conditions-headings">
        {"Limited Liability"}
      </h2>
      <div className="terms-and-conditions-body">
        {`Scape and Scape's service providers will use reasonable endeavors to maintain uninterrupted 
        service. The stability and speed of the service are subject to the performance of the providers' network 
        platform and the gateway does not hold all its service providers to minimum service level agreements. 
        You are liable for any bugs, viruses, or other malicious software that may infiltrate your device or the 
        network as a result of your online activities. Scape may report any illegal activity detected on the network 
        to the relevant authorities. You agree that your use of this service will be at your own risk. Scape 
        and its service providers do not accept liability for:`}
      </div>
      <ul className="terms-and-condition-list-items">
        <li>{`Loss or corruption of data in any circumstance; or`}</li>
        <li>{`Any defamatory, offensive or illegal intent of third parties, websites or films viewed on the service.`}</li>
      </ul>
      <h2 className="terms-and-conditions-headings">
        {`Acceptable Use`}
      </h2>
      <div className="terms-and-conditions-body">
        {`You agree to use the service in accordance with all relevant legislation and maintain the security of the 
        property’s ITnetwork and facilities. The activities listed below are examples of unacceptable use of the network 
        and may result inthe cancellation of your Internet account. You must not use this service:`}
      </div>
      <ul className="terms-and-condition-list-items">
        <li>{`to breach any Commonwealth or State law;`}</li>
        <li>{`to breach the Scape House Rules (e.g. Cyber-Bullying);`}</li>
        <li>{`by sharing your personal internet account with others;`}</li>
        <li>{`to tamper with the system or any of the data ports or wireless access points throughout the property; and/or`}</li>
        <li>{`to receive, access, download, display, transmit and/or make available any illegal materials via any media.`}</li>
      </ul>
      <h2 className="terms-and-conditions-headings">
        {"Changes"}
      </h2>
      <div className="terms-and-conditions-body">
        {`Scape reserves the right to change the above terms and conditions from time to time. Accessing the service by you 
        willbe constituted as acceptance of any adjustments to the terms and conditions.`}
      </div>
      <h2 className="terms-and-conditions-headings">
        {"Privacy Policy"}
      </h2>
      <div className="terms-and-conditions-body">
        {`Scape's Privacy Policy complies with the Australian Privacy Act 1988 amended to conform to the Privacy 
        Amendment(Enhancing Privacy Protection) Act 2012 (the “Privacy Act”). The Australian Privacy Principles (APP), 
        contained in thePrivacy Act, impose privacy obligations on organisations to comply with the collection, use, 
        storage and disclosure ofpersonal information. For the full policy please go to Scape’s website `} 
        <a href="https://www.scape.com.au/" target="_blank">
          <strong className="terms-and-condition-highlight">
            {`www.scape.com.au`}
          </strong>
        </a>
      </div>
      <h3 className="terms-and-conditions-subheadings">
        {`For Assistance See Reception`}
      </h3>
    </div>
  );
};

export default TermsAndCondition;
